import React, { useState, useMemo, useEffect, useCallback } from "react";
import moment from "moment";
import axios from "axios";
import { config } from "../assets/config/config";
import ThemedSuspense from "../components/ThemedSuspense";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { generalService } from "../services/general.service";

const apiUrl = config.api.url;

// create context
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  var history = useHistory();
  const [faqData, setFaqlist] = useState([]);
const refreshFaq = useCallback(() => {
    return generalService
      .getFaq()
      .then((data) => {
        setFaqlist(data.results);
        if(data?.results[0]?.Fav_image != ""){
          updateTitleIcon(data?.results[0]?.Fav_image)
        }
      })
      .catch((err) => {
        return null;
      });
  }, []);

  useEffect(() => {
    refreshFaq();
  }, [refreshFaq]);
  const filePath = "https://assets.ratebolo.com/";
  const updateTitleIcon = (iconUrl) => {

     // Set favicon dynamically
     const faviconLink = document.querySelector("link[rel*='icon']") || document.createElement('link');
     faviconLink.type = 'image/x-icon';
     faviconLink.rel = 'shortcut icon';
     faviconLink.href = `${filePath}${iconUrl}`;
 
     document.head.appendChild(faviconLink);
  }
  const [isLoaded, setLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  const refreshTokens = useCallback(() => {
    return axios
      .post(`${apiUrl}/v1/auth/refresh-tokens`, {})
      .then((response) => {
        setAccessToken(response?.data?.token);
        setUser(response?.data?.user);
        return response;
      })
      .catch((error) => {
        setUser(null);
        setAccessToken(null);
        return error;
      });
  }, []);

  const startSilentRefresh = useCallback(() => {
    if (accessToken) {
      const tokenExpires = moment(accessToken.expires);
      const tokenMaxAge = tokenExpires.diff(moment().add(1, "minutes"));
      setTimeout(() => {
        refreshTokens();
      }, tokenMaxAge);
    }
  }, [accessToken, refreshTokens]);

  const syncLogout = (event) => {
    if (event.key === "logout") {
      setAccessToken(null);
      setUser(null);
    }
  };

  useEffect(() => {
    const interceptorId = axios.interceptors.request.use(
      (config) => {
        config.withCredentials = true;
        config.credentials = "include";
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(interceptorId);
    };
  }, [accessToken]);

  useEffect(() => {
    refreshTokens().then((response) => {
      setLoaded(true);
    });
  }, [refreshTokens]);

  useEffect(() => {
    startSilentRefresh();
  }, [accessToken, startSilentRefresh]);

  useEffect(() => {
    window.addEventListener("storage", syncLogout);
    return function cleanup() {
      window.removeEventListener("storage", syncLogout);
    };
  }, []);

  const value = useMemo(() => {
    const register = (username, email, password) => {
      return axios
        .post(`${apiUrl}/v1/auth/register`, {
          name: username,
          email: email,
          password: password,
        })
        .then((response) => {
          setAccessToken(response?.data?.token);
          setUser(response?.data?.user);
          startSilentRefresh();
        });
    };

    const login = (email, password) => {
      return axios
        .post(`${apiUrl}/v1/auth/login`, {
          email: email,
          password: password,
        })
        .then((response) => {
          const userData = response?.data?.user;
          if (userData && userData.status === "1") {
            setAccessToken(response?.data?.token);
            setUser(userData);
            startSilentRefresh();
            localStorage.setItem("success", "Login successful"); // Set success message
            if (localStorage.getItem("error") !== null) {
              localStorage.removeItem("error");
            }
          } else {
            localStorage.setItem("error", "User is inactive");
          }
        })
        .catch((error) => {
          localStorage.setItem("error", "Incorrect email or password");
        });
    };
    
    

    const githubAuth = () => {
      window.location.href = `${apiUrl}/v1/auth/github`;
    };

    const googleAuth = () => {
      window.location.href = `${apiUrl}/v1/auth/google`;
    };

    const logout = () => {
      setAccessToken(null);
      setUser(null);
      return axios
        .post(`${apiUrl}/v1/auth/logout`, {})
        .then((response) => {
          window.localStorage.setItem("logout", moment());
        })
        .catch((err) => {});
    };
    

    const forgotPassword = (email) => {
      return axios.post(`${apiUrl}/v1/auth/forgot-password`, {
        email: email,
      });
    };

    const resetPassword = (password, resetToken) => {
      console.log("🚀 ~ resetPassword ~ resetToken:", resetToken)
      return axios.post(
        `${apiUrl}/v1/auth/reset-password?token=${resetToken}`,
        {
          password: password,
        }
      );
    };

    const verifyEmail = (emailVerificationToken) => {
      return axios.post(
        `${apiUrl}/v1/auth/verify-email?token=${emailVerificationToken}`,
        {}
      );
    };

    return {
      user,
      setUser,
      register,
      login,
      githubAuth,
      googleAuth,
      logout,
      forgotPassword,
      resetPassword,
      verifyEmail,
    };
  }, [user, startSilentRefresh]);

  if (!isLoaded) {
    return <ThemedSuspense />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
