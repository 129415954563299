import React from 'react'
import { ColorRing } from 'react-loader-spinner'
function ThemedSuspense() {
  return (
    <div className="loader-container">
                <ColorRing
                  visible={true}
                  height="80"
                  width="80"
                  ariaLabel="blocks-loading"
                  wrapperStyle={{}}
                  wrapperClass="blocks-wrapper"
                  colors={['#fea412', '#e49310', '#cb830e', '#b1720c', '#98620a']}
                /></div>
  )
}

export default ThemedSuspense
