// import React, { useContext, useState, useEffect } from 'react'
// import { Route, Redirect } from 'react-router-dom'

// import { AuthContext } from '../../context/AuthContext'

// export default function ProtectedRoute(props) {
// 	const { user } = useContext(AuthContext)
//   const { component: Component, ...remProps } = props
//   const [pathname] = useState(window.location.pathname + window.location.search)

//   useEffect(() => {
//   	if(user) {
//   		window.sessionStorage.removeItem('authRedirect')
//   	} else {
//   		window.sessionStorage.setItem('authRedirect', pathname)
//   	}
//   }, [user, pathname])

//   return (
// 	<Route 
//     	{...remProps} 
// 		render={remProps => (
// 			user ?
// 				<Component {...remProps} /> :
// 				<Redirect to='/auth' />
//         )} 
//     />
// 	);
// }
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Route, Redirect,Link, useHistory } from 'react-router-dom'

import { AuthContext } from '../../context/AuthContext'
// import { role_usersService } from '../../services'

export default function ProtectedRoute(props) {
	var { user,RoleuserAccessRights } = useContext(AuthContext)
	var {  } = useContext(AuthContext)
	const history = useHistory()

	const { component: Component, ...remProps } = props;

	var permissionsData;

	const checkAccess =  useCallback(
		() => {
			
			if (user && user.role == 'roleuser' && RoleuserAccessRights) {

			   permissionsData = RoleuserAccessRights?.role?.user_permissions
				var getAccessData = permissionsData.find((x) => x.path == props.location.pathname)

				if (getAccessData) {
					if(getAccessData.access.list!=true)
					{
						history.push(`/app/dashboard`)

					}	
				}
			
		} 

		
		},
		[props.location.pathname]
	  )
	

	useEffect(() => {

		checkAccess()
	
	}, [props]);

	return (
		<Route 
			{...remProps} 
			render={remProps => (
				user ?
					<Component {...remProps} /> :
					<Redirect to='/auth' />
			)} 
		/>);
}

