import React, { useState, useEffect, useContext, useCallback,lazy } from "react";
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import ProtectedRoute from './components/Routes/ProtectedRoute'
import ForwardRoute from './components/Routes/ForwardRoute'
import { generalService } from "../src/services/general.service";

const Layout = lazy(() => import('./containers/Layout'))
const Auth = lazy(() => import('./containers/Auth'))
const Landing = lazy(() => import('./pages/Landing'))
const EmailVerification = lazy(() => import('./pages/EmailVerification'))
const Login = lazy(() => import('./pages/Login'))
const CreateCms = lazy(() => import('./components/Modals/cms/CrateCmsModal'))
const CreateBrand = lazy(() => import('./components/Modals/CreateBrandModal'))
const CreateFaq = lazy(() => import('./components/Modals/CreateFaqModal'))
const CreateEmailTemplate = lazy(()=> import ('./components/Modals/CreateEmailTemplateModal'))
const CMSPolicy = lazy(() => import('./containers/CMSPolicy'))

function App() {
  const [faqData, setFaqlist] = useState([]);
const refreshFaq = useCallback(() => {
    return generalService
      .getFaq()
      .then((data) => {
        setFaqlist(data.results);
        if(data?.results[0]?.Fav_image != ""){
          updateTitleIcon(data?.results[0]?.Fav_image)
        }
      })
      .catch((err) => {
        return null;
      });
  }, []);

  useEffect(() => {
    refreshFaq();
  }, [refreshFaq]);
  const filePath = "https://assets.ratebolo.com/";
  const updateTitleIcon = (iconUrl) => {

     // Set favicon dynamically
     const faviconLink = document.querySelector("link[rel*='icon']") || document.createElement('link');
     faviconLink.type = 'image/x-icon';
     faviconLink.rel = 'shortcut icon';
     faviconLink.href = `${filePath}${iconUrl}`;
 
     document.head.appendChild(faviconLink);
  }
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <ForwardRoute path="/auth" component={Auth} />
          {/* Place new routes over this */}
          <ProtectedRoute path="/app" component={Layout} />
          <Route exact path="/view-cms/:id" component={CMSPolicy} />
          <Route exact path="/verify-email" component={EmailVerification} />
          <Route exact path="/land" component={Landing} />
          <Route exact path="/cms/create-cms" component={CreateCms} />
          <Redirect from="*" to="/auth/login" />
          <Route exact path="/brand/create-brand" component={CreateBrand} />
          <Route exact path="/faq/create-faq" component={CreateFaq} />
          <Route exact path="/template/create-template" component={CreateEmailTemplate} />
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </>
  )
}

export default App
