import axios from 'axios'
import { config } from '../assets/config/config'

const apiUrl = config.api.url


const createFaq =(Fav_image,logo_image,bucketName,site_name, description,gst, contact_address,contact_email,contact_phone_number,Meta_Keyword,userway_youtupe_link,Meta_Description,Meta_Tags, copyrights,status,free_offer_days,notification_sts,isFreshList) => {
  var formData = new FormData();  
  formData.append('Fav_image', Fav_image);
  formData.append('logo_image',  logo_image);
  formData.append('bucketName', bucketName);
  formData.append('site_name', site_name);
  formData.append('description', description);
  formData.append('gst',gst);
  formData.append('contact_address', contact_address);
  formData.append('contact_email', contact_email);
  formData.append('contact_phone_number', contact_phone_number);
  formData.append('Meta_Keyword',Meta_Keyword);
  formData.append('userway_youtupe_link',userway_youtupe_link);
  formData.append('Meta_Description',Meta_Description);
  formData.append('Meta_Tags',Meta_Tags);
  formData.append('copyrights',copyrights);
  formData.append('status',status);
  formData.append('free_offer_days',free_offer_days)
  formData.append('pushnotification_status',notification_sts)
  formData.append('isFreshList',isFreshList)

  return axios.post(`${apiUrl}/v1/general`, formData)
  }
const getFaq = (page) => {
	return axios.get(`${apiUrl}/v1/general?limit=${config.users.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
}

const deleteFaq = (faq_id) => {
  return axios.delete(`${apiUrl}/v1/general/${faq_id}`, {}).then(response => {
    return response.data
  })

	// 
}


const getFaqById = (general_id) => {
  return axios.get(`${apiUrl}/v1/general/${general_id}`, {})
      .then(response => {
          return response.data
      })
}

const updateFaq = (faqId,
Fav_image,
  logo_image,bucketName,site_name, description,gst, contact_address,contact_email,contact_phone_number,Meta_Keyword,userway_youtupe_link,Meta_Description,Meta_Tags, copyrights,status,free_offer_days,notification_sts,isFreshList) => {
  var formData = new FormData();  
  formData.append('Fav_image', Fav_image);
  formData.append('logo_image',  logo_image);
  formData.append('bucketName', bucketName);
  formData.append('site_name', site_name);
  formData.append('description', description);
  formData.append('gst', gst);
  formData.append('contact_address', contact_address);
  formData.append('contact_email', contact_email);
  formData.append('contact_phone_number', contact_phone_number);
  formData.append('Meta_Keyword',Meta_Keyword);
  formData.append('userway_youtupe_link',userway_youtupe_link);
  formData.append('Meta_Description',Meta_Description);
  formData.append('Meta_Tags',Meta_Tags);
  formData.append('copyrights',copyrights);
  formData.append('status',status);
  formData.append('free_offer_days',free_offer_days)
  formData.append('pushnotification_status',notification_sts)
  formData.append('isFreshList',isFreshList)
 return axios.patch(`${apiUrl}/v1/general/${faqId}`, formData)
  }


const brandFilter = (searchkey,page, user_id) => {
	if(user_id !== undefined){
    return axios.get(`${apiUrl}/v1/general?site_name=${searchkey}&limit=${config.users.resultsPerPage}&page=${page}&created_by=${user_id}`, {})
		.then(response => {
	    return response.data
	  })
  }else{
    return axios.get(`${apiUrl}/v1/general?site_name=${searchkey}&limit=${config.users.resultsPerPage}&page=${page}`, {})
		.then(response => {
	    return response.data
	  })
  }
}


export const generalService = {
  createFaq,
  updateFaq,
  getFaq,
  getFaqById,
  deleteFaq,
  brandFilter
  
}