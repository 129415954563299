const Joi = require('joi');

const envVarsSchema = Joi.object()
  .keys({
    NODE_ENV: Joi.string().valid('production', 'development', 'demo', 'test').required(),
    REACT_APP_API_URL: Joi.string().allow('').default('').description('backend server url'),
    REACT_APP_TINYMCE_KEY: Joi.string().description('TinyMCE key'),
    REACT_APP_USERS_PER_PAGE: Joi.number().default(10).description('number of users per page in users table'),
    AWS_IAM_USER_KEY: Joi.string().description('aws key to store the images in s3'),
    AWS_IAM_USER_SECRET: Joi.string().description('aws secretkey to store the images in s3'),
    AWS_BUCKET_NAME: Joi.string().description('aws bucket name to store the images in s3'),
    AWS_URL: Joi.string().description('aws url to store the images in s3'),
  })
  .unknown();

const { value: envVars, error } = envVarsSchema.prefs({ errors: { label: 'key' } }).validate(process.env);

if (error) {
  throw new Error(`Config validation error: ${error.message}`);
}

console.log("envVars.REACT_APP_API_URL ",envVars.REACT_APP_API_URL )

export const config = {
  api: {
    url: envVars.NODE_ENV === 'production' ||  envVars.NODE_ENV === 'development' ? envVars.REACT_APP_API_URL : '',
  },
  stripe: {
    publicKey: envVars.REACT_APP_STRIPE_PUBLIC_KEY,
  },
  users: {
  	resultsPerPage: envVars.REACT_APP_USERS_PER_PAGE,
  },
  categories: {
  	resultsPerPage: envVars.REACT_APP_USERS_PER_PAGE,
  },
  Specification: {
  	Specification: envVars.REACT_APP_USERS_PER_PAGE,
  },
  cmspage: {
  	resultsPerPage: envVars.REACT_APP_USERS_PER_PAGE,
  },
  taluk: {
  	resultsPerPage: envVars.REACT_APP_USERS_PER_PAGE,
  },
  subscription: {
  	resultsPerPage: envVars.REACT_APP_USERS_PER_PAGE,
  },
  tinymce: {
    apiKey: envVars.REACT_APP_TINYMCE_KEY,
  },
  
  AWS_S3: {
    accessKeyId: envVars.AWS_IAM_USER_KEY,
    secretAccessKey: envVars.AWS_IAM_USER_SECRET,
    url: envVars.AWS_URL,
    bucketName: envVars.AWS_BUCKET_NAME,
  },

  
};
